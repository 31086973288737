<template>
  <el-card id="department">
    <header>
      <h1>部门管理</h1>
      <div class="search">
        <el-button
          type="success"
          icon="el-icon-office-building"
          @click="addDepart()"
          class="addDepart"
          size="small"
          >添加部门</el-button
        >
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="departName"
          label="部门名称"
          align="center"
          width="500"
        ></el-table-column>
        <el-table-column label="操作" align="center" style="dispaly: flex">
          <template #default="scope">
            <el-button type="danger" @click="del(scope)" size="small"
              >删除部门</el-button
            >
            <el-button type="primary" @click="updateName(scope)" size="small"
              >修改部门名称</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 添加弹出框 -->
    <el-dialog title="添加部门" :visible.sync="addVisible" width="35%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="公司列表" v-if="realname == 'admin'">
          <el-select
            v-model="top_id"
            placeholder="请选择公司"
            style="width: 100%"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门名称">
          <el-input
            v-model="form.departName"
            placeholder="请输入部门名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 修改部门名称弹出框 -->
    <el-dialog title="修改部门名称" :visible.sync="updateVisible" width="30%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="部门名称">
          <el-input
            v-model="departName"
            placeholder="请输入部门名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="updateVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveUpdate">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { sysDepartList, sysDepartAdd, sysDepartDelete } from "@/api/sys_depart";
import { userFindlist } from "@/api/user";
export default {
  data() {
    return {
      addVisible: false,
      updateVisible: false,
      form: {
        departName: "",
      },
      departName: null,
      departId: null,
      options: [],
      top_id: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
      realname: null,
      id: null,
    };
  },
  created() {
    (this.realname = localStorage.getItem("realname")),
      (this.id = localStorage.getItem("id"));
    this.getList();
  },
  methods: {
    // 展示
    getList() {
      let params = {};
      if (this.realname == "admin") {
        params = {
          page: this.page,
          size: this.size,
        };
      } else {
        params = {
          page: this.page,
          size: this.size,
          topId: this.id,
        };
      }
      let res = sysDepartList(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
      });
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 点击添加按钮
    addDepart() {
      this.form = [];
      this.top_id = null;
      this.addVisible = true;
      let params = {
        page: this.page,
        size: this.size,
        parentId: 0,
      };
      let res = userFindlist(params);
      res.then((res) => {
        this.options = res.data.data;
      });
    },
    // 添加确定按钮
    saveAdd() {
      let params = {};
      if (this.realname == "admin") {
        params = {
          top_id: this.top_id,
          departName: this.form.departName,
        };
      } else {
        params = {
          departName: this.form.departName,
        };
      }
      let res = sysDepartAdd(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.getList();
        } else {
          Message.error(res.data.message);
        }
      });
      this.addVisible = false;
    },
    // 删除部门
    del(row) {
      let params = {
        id: row.row.id,
        departName: row.row.departName,
      };
      this.$confirm("确认删除部门吗，删除后不可恢复?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let res = sysDepartDelete(params);
          res.then((res) => {
            if (res.data.statusCode == "00000") {
              Message.success(res.data.message);
              this.getList();
            } else {
              Message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
    // 修改部门名称
    updateName(row) {
      this.departName = row.row.departName;
      this.departId = row.row.id;
      this.updateVisible = true;
      //   console.log(row.row);
    },
    // 编辑部门
    saveUpdate() {
      let params = {
        departName: this.departName,
        id: this.departId,
      };
      let res = sysDepartUpdate(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.updateVisible = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.updateVisible = false;
          this.getList();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#department {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>